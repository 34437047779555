import React from "react"

import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import Image from "gatsby-image"

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { categoryColor } from '../utils/categoryColor'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  listWapper: {
    display: `flex`,

  },
  title: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(12),
    },
  },
  horizontalList: {
    marginLeft: 0,
    overflow: `auto hidden`,
    display: `flex`,
    flexFlow: `row nowrap`,
    WebkitBoxPack: `start`,
    justifyContent: `flex-start`,

    overflowScrolling: `touch`,
    WebkitOverflowScrolling: `touch`,
    width: `100%`,
    paddingBottom: theme.spacing(1),
  },
  item: {
    boxSizing: `border-box`,
    display: `inline-block`,
    height: `auto`,
    minWidth: `90%`,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: `30%`,
    },
  },
  itemTop: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(12),
    },
  },
  cardText: {
    whiteSpace: `normal`,
    textOverflow: `ellipsis`,
    maxHeight: 80,
    overflow: `hidden`,
    position: `relative`,
    "&:after": {
      content: "…",
      position: `absolute`,
      display: `block`,
      bottom: 0,
      right: 0,
    }
  },
  cardContent: {
    height: 180,
  },
  media: {
    height: 220,
  },
  button: {
    marginLeft: `auto`,
  },
  price: {
    marginLeft: theme.spacing(1),
  },
  buttontext: {
    marginLeft: theme.spacing(1),
  },
  date: {
    color: `#00b0ff`,
  },
  categoryArea: {
    padding: `4px`,
    marginBottom: theme.spacing(0),
    color: `white`,
    width: `auto`,
    textAlign: `center`,
  },
  category: {
    padding: theme.spacing(1),
    fontSize: `12px`,
  },
  moreButtonArea: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  },
  moreButton: {
    marginLeft: `5rem`,
    marginRight: `5rem`,
  }
}));

const ScrollItem = ({ items, isRelated = false }) => {

  const classes = useStyles();

  const postsPerPage = 9
  const [pageCount, setPageCount] = React.useState(1)
  const [isMoreButton, setIsMoreButton] = React.useState(true)


  const cardDiscription = (text) => {
    if (text.length > 60)
      return `${text.slice(0, 60)}....`;
    else
      return text;
  }

  const handleMoreClick = () => {
    setPageCount(pageCount+1)
    if (items.length < postsPerPage * (pageCount+1)) {
      setIsMoreButton(false)
    }
    return
  }

  return (
    <div>
      <div className={classes.listWapper}>
        <ul className={classes.horizontalList}>
          {items.map((item, index) => {
            let id, title, description, date, cover, category, buttonLink;

            const post = item.node;
            id = post.id;
            title = post.frontmatter.title || post.fields.slug
            description = post.frontmatter.description || post.excerpt;
            date = post.frontmatter.date;
            const image = post.frontmatter?.cover?.childImageSharp?.fluid
            cover = <Image
              fluid={{ ...image, aspectRatio: 16 / 9 }}
              objectFit="contain"
              style={{width: `100%`}}
              alt={title}
            />
            buttonLink = post.fields.slug;
            category = post.frontmatter.category[0];
            const itemClassName = index === 0 ? `${classes.item} ${classes.itemTop}` : classes.item;

            if (postsPerPage * pageCount > index) {
              return (
                <li className={itemClassName} key={id}>
                  <Tooltip title={cardDiscription(description)} arrow>
                    <Link to={buttonLink}>
                      <Card elevation={0} square>
                        {cover}
                        <CardContent className={classes.cardContent}>
                          <Typography variant={isRelated ? "body1" : "h6"} component="div">
                            {title}
                          </Typography>
                          <Typography variant="body2" className={classes.date} component="p">
                            {date}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                             {cardDiscription(description)}
                          </Typography>
                        </CardContent>
                        <Typography variant="body2" className={classes.categoryArea} component="p">
                          <span className={classes.category} style={{background: categoryColor(category)}}>
                            {category.toUpperCase()}
                          </span>
                        </Typography>
                      </Card>
                    </Link>
                  </Tooltip>
                </li>
              )
            }
            else {
              return ""
            }
          })}
          <li className={classes.moreButtonArea}>
            <div className={classes.moreButton}>
              <IconButton size="medium" variant="contained" color="primary" onClick={handleMoreClick} disabled={!isMoreButton}>
                <ArrowForwardIosIcon />
              </IconButton>
              More...
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ScrollItem
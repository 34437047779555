import React from "react"
import { graphql, Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'

import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Work from "../components/work"
import ScrollItem from "../components/scrollItem"
import AdsCard from "../components/adsCard"

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges

  const developments = posts.filter((v) => v.node.frontmatter.category[0] === "development");
  const howto = posts.filter((v) => v.node.frontmatter.category[0] === "howto");
  const foods = posts.filter((v) => v.node.frontmatter.category[0] === "food");

  const classes = useStyles()

  const cardDiscription = (text) => {
    if (text.length > 60)
      return `${text.slice(0, 60)}....`;
    else
      return text;
  }

  return (
    <Layout location={location} title="Top">
      <SEO title="ホーム" slug="/"/>

      <section>
        <Container>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item md={9} sm={12}></Grid>
            <Grid item md={3} sm={12}>
              <h3 className={classes.title}>
                DEVELOPMENT
                <Typography className={classes.subTitle} variant="body2" component="div">
                  ITエンジニアとして得たスキルや知識、開発した技術内容などのアウトプット
                </Typography>
              </h3>
            </Grid>
          </Grid>
        </Container>
        <ScrollItem items={developments} />
      </section>

      <section>
        <Container>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item md={4} sm={12}>
            </Grid>
            <Grid item md={8} sm={12}>
              {/* ADS:  */}
              {/* <AdsCard slot="9009456012" /> */}
              <AdsCard slot="5723982973" />
              <br />
              <h3 className={classes.title}>
                HOW TO
                <Typography className={classes.subTitle} variant="body2" component="div">WEBマーケティングに関する情報や知識や、お役立ち情報
                </Typography>
              </h3>
              {howto.map((item, index) => {
                const image = item.node.frontmatter?.cover?.childImageSharp?.fluid
                const title = item.node.frontmatter.title;
                const date = item.node.frontmatter.date;
                const description = item.node.frontmatter.description || item.node.excerpt;
                const link = item.node.fields.slug;
                return(
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    key={item.node.id}
                    className={classes.howTo}
                  >
                    <Grid item md={4} sm={12}>
                      <Link to={link} className={classes.link}>
                        <Image
                          fluid={{ ...image, aspectRatio: 16 / 9 }}
                          objectFit="contain"
                          style={{width: `100%`}}
                          alt={title}
                        />
                      </Link>
                    </Grid>
                    <Grid item md={8} sm={12}>
                      <Link to={link} className={classes.link}>
                        <Typography variant="h6" component="div">
                          {title}
                        </Typography>
                      </Link>
                      <Typography variant="body2" className={classes.date} component="p">
                        {date}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {cardDiscription(description)}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Container>
      </section>
      <section>
        <Container>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item md={9} sm={12}>
              <h3 className={classes.title}>
                GOURMET DEVELOPMENT
                <Typography className={classes.subTitle} variant="body2" component="div">
                  グルメのレシピ中心の情報です。
                </Typography>
              </h3>
            </Grid>
            <Grid item md={3} sm={12}></Grid>
          </Grid>
        </Container>
        <ScrollItem items={foods} />
      </section>
      <Work />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            category
            tags
            cover {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.spacing(6),
    color:`gray`,
  },
  howTo: {
    marginBottom: theme.spacing(3),
  },
  date: {
    color: `#00b0ff`,
    marginBottom: `0.7rem`,
  },
  link: {
    color: `black`,
    textDecoration: `none`,
    boxShadow: `none`,
  },
}))

export default BlogIndex